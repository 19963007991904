import React from "react";

const Faq = () => {
return (
    <section className="faqs default-spacing">
      <h2>FAQ</h2>
      <div className="section-content__wide">
        <article className="faq">
          <h4>1. Who can participate in the games?</h4>
          <p>Owners of player NFTs can participate in the games. We will start by minting 456 players in our first round. Each round we will mint 456 additional players who each get a chance to play in their own 456-player game. We will cap off the game at 4,560 total players.</p>

          <p>Guards, VIPs, The Detective and the Frontman all have separate competitions the compete in.</p>

          <h4>2. What's the prize?</h4>

          <p>The winner of the first round of games will win 11.4 ETH. The prize pool will increase by 2.28 ETH for the next round.</p>



          <h4>3. What do we need to know about the games?</h4>

          <p>The games will take place on Discord and our website. There will be 6 rounds to each game, and at the end one player will be declared the winner. Each game will have a 24 hour window to compete in to respect different timezones.</p>



          <h4>4. What if I lose?</h4>

          <p>You only get one shot at your 456-player pool game.</p>

          <p>However, we will have 2 slotted games &ndash; after the 5th game and after the 10th game &ndash; for eliminated players to get another chance at a prize.</p>

          <p>The first prize for eliminated players will consist of 100% of the royalty fees up to that point. The second prize for eliminated players will be the rest of the royalties collected plus 10 ETH.</p>

          <p>You'll need to keep your eliminated NFT to participate in the elimination games.</p>




          <h4>5. What will it cost to mint a player?</h4>

          <p>It will cost .055 ETH to mint one of the first 456 players in the game. Next batches will cost .07 ETH.</p>



          <h4>6. What happens after all players mint?</h4>

          <p>Once all 4,560 players are minted into the game and all prize money has been distributed, the game will be burned and forgotten about.</p>

          <p>Eliminated player and guard NFT holders will get a complimentary NFT in our next game project.</p>

          <p>Winning players and special character NFT holders will get a complimentary NFT.</p>

          <h4>7. Can I play with more than 1 player?</h4>

          <p>Yes. You can mint up to 10 players per wallet. </p>

          <p>If you have more than 1 player in your wallet, you will need to play the game for each of your players separately. The website will have instructions on how to do so.</p>

        </article>
      </div>
    </section>
  )
}

export default Faq;