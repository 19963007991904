import React from "react";

const Footer = () => {
return (
    <footer>
      <p className="disclaimer">We are not associated with Squid Game or any TV show. This game is just for fun, all eliminated players are safe.</p>
    </footer>
  )
}

export default Footer;